"use client";

import {
  AccountCancellationForm,
  submitAccountCancellationForm,
} from "@/app/data/account/account-cancellation.action";
import { useTranslation } from "@/app/i18n/client";
import TextInputField from "@/components/ui/Form/TextInputField";
import { CGCPrismicContext } from "@/prismicio";
import { styleGuideTypography } from "@/utils/style-guide-typography";
import { Button } from "@cgc/ui/button";
import { useModal } from "@cgc/ui/modal";
import { Heading, Paragraph } from "@cgc/ui/typography";
import { Content } from "@prismicio/client";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import * as Yup from "yup";

/**
 * Props for `CancellationForm`.
 */
export type CancellationFormProps = SliceComponentProps<
  Content.CancellationFormSlice,
  CGCPrismicContext
>;

/**
 * Component for "CancellationForm" Slices.
 */
const CancellationForm = ({
  slice,
  context,
}: CancellationFormProps): JSX.Element => {
  const { t } = useTranslation(context.lang, "slices");
  const [message, setMessage] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const { Modal, setIsOpen } = useModal();

  const validationSchema = Yup.object<AccountCancellationForm>({
    firstName: Yup.string()
      .trim()
      .required(t("account-cancellation.fields.firstName.validation.required")),
    lastName: Yup.string()
      .trim()
      .required(t("account-cancellation.fields.lastName.validation.required")),
    email: Yup.string()
      .email(t("account-cancellation.fields.email.validation.invalid"))
      .trim()
      .required(t("account-cancellation.fields.email.validation.required")),
    address: Yup.string()
      .trim()
      .required(t("account-cancellation.fields.address.validation.required")),
    city: Yup.string()
      .trim()
      .required(t("account-cancellation.fields.city.validation.required")),
    postcode: Yup.string()
      .trim()
      .required(t("account-cancellation.fields.postcode.validation.required")),
    terminationDate: Yup.string()
      .trim()
      .required(
        t("account-cancellation.fields.terminationDate.validation.required"),
      ),
  });

  const handleFormSubmit = async (
    values: AccountCancellationForm,
    formikHelpers: FormikHelpers<AccountCancellationForm>,
  ): Promise<any> => {
    try {
      await submitAccountCancellationForm(values);
      formikHelpers.setSubmitting(false);
      setSuccess(true);
      setMessage("Your cancellation was successful.");
    } catch (error) {
      setSuccess(false);
      setMessage(
        "Your cancellation was not successful. Please try again or contact .",
      );
    }
    setIsOpen(true);
  };

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="text-cgc-blue w-full"
    >
      <div className="relative mx-auto flex w-full max-w-screen-xl flex-col items-center gap-6 px-8 py-6 lg:px-16 lg:py-12">
        {/* Copy */}
        <div className="text-balance text-center">
          <PrismicRichText
            field={slice.primary.header}
            components={styleGuideTypography}
          />
        </div>

        <Formik<AccountCancellationForm>
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            address: "",
            city: "",
            postcode: "",
            terminationDate: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="w-full space-y-1">
              <div className="flex w-full flex-col lg:flex-row lg:gap-4">
                <TextInputField
                  name="firstName"
                  placeholder={t(
                    "account-cancellation.fields.firstName.placeholder",
                  )}
                  label={t("account-cancellation.fields.firstName.label")}
                  wrapperClassName="w-full"
                />

                <TextInputField
                  name="lastName"
                  placeholder={t(
                    "account-cancellation.fields.lastName.placeholder",
                  )}
                  label={t("account-cancellation.fields.lastName.label")}
                  wrapperClassName="w-full"
                />
              </div>

              <TextInputField
                name="email"
                placeholder={t("account-cancellation.fields.email.placeholder")}
                label={t("account-cancellation.fields.email.label")}
              />

              <TextInputField
                name="address"
                placeholder={t(
                  "account-cancellation.fields.address.placeholder",
                )}
                label={t("account-cancellation.fields.address.label")}
              />

              <div className="flex w-full flex-col lg:flex-row lg:gap-4">
                <TextInputField
                  name="city"
                  placeholder={t(
                    "account-cancellation.fields.city.placeholder",
                  )}
                  label={t("account-cancellation.fields.city.label")}
                  wrapperClassName="w-full"
                />

                <TextInputField
                  name="postcode"
                  placeholder={t(
                    "account-cancellation.fields.postcode.placeholder",
                  )}
                  label={t("account-cancellation.fields.postcode.label")}
                  wrapperClassName="w-full"
                />
              </div>

              <TextInputField
                name="terminationDate"
                placeholder={t(
                  "account-cancellation.fields.terminationDate.placeholder",
                )}
                label={t("account-cancellation.fields.terminationDate.label")}
              />

              <Button
                color="primary"
                className="!mt-4 w-full max-w-96"
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                {t("account-cancellation.fields.submit.label")}
              </Button>
            </Form>
          )}
        </Formik>
      </div>

      <Modal>
        <Heading
          size="h2"
          className={success ? "text-cgc-blue" : "text-cgc-xmas-red"}
        >
          {success ? "Success" : "Error"}
        </Heading>
        <Paragraph
          size="p1"
          className={success ? "text-cgc-blue" : "text-cgc-xmas-red"}
        >
          {message}
        </Paragraph>
      </Modal>
    </section>
  );
};

export default CancellationForm;
